import { Page, HttpStatus } from '../../components';
import { Helmet } from 'react-helmet';

const helmetConfig = {
  link: [
    {
      rel: 'canonical',
      href: 'https://vehiclehistory.in/'
    }
  ]
};

export default function NotFound() {
  return (
    <Page title="Not Found">
      <Helmet {...helmetConfig} />
      <HttpStatus statusCode={404}>
        <h3>404 - Page Not Found.</h3>
      </HttpStatus>
    </Page>
  );
}
